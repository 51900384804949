<template>
  <div class="order-item">
    <div class="header">
      <span class="time">{{ info.up | dateFormat }}</span>
      <span class="order">订单号：{{ info.orderno }}</span>
      <span class="count">数量</span>
      <span class="price">金额</span>
      <span class="operate">操作</span>
    </div>
    <div class="cotainer">
      <div class="ware-list">
        <div class="ware-item" v-for="(item, index) in info.products" :key="index">
          <img :src="item.product.pic.picUrl" alt />
          <div class="ware-info">
            <span class="warename">{{ item.product.title }}</span>
            <span>{{ item.product.dskudes }}</span>
            <span>{{ item.product.drskudes }}</span>
          </div>
          <div class="count">{{ item.product.pnum }}</div>
        </div>
      </div>
      <div class="price">
        <span>{{ info.price | toRMB }}</span>
        <span>(共计{{ wareNum(info.products) }}件)</span>
      </div>
      <div class="operate">
        <span class="status">{{status}}</span>
        <span @click="operate('Detail')">订单详情</span>
        
        <span @click="operate('Logistics')" 
        v-if="(info.ostatus >= 210 && info.ostatus <= 300) || info.ostatus ===5000">
        物流</span>
        <span v-if="info.ostatus == 0" 
        @click="cancelorder(info.orderid)">取消订单</span>
        
        <span 
        v-if="info.ostatus === 5000 || info.ostatus === 5010"
        @click="delorder(info.orderid)">
        删除订单</span>

        <span v-if="info.paymethod==1 && info.paystatus == 5  && info.exchangestatus != 1"
        @click="tolastpay(info)">支付尾款</span>

        <span v-if="info.ostatus  === 300 && info.appraise == 0"
        @click="evaluate(info)">评价</span>

        <span v-if="info.ostatus  === 300 && info.appraise == 1">已评价</span>

        <span v-if="info.ostatus === 210"
        @click="receive(info.orderid)">确认收货</span>

         <div v-if="info.exchangestatus === 0 ">
        <span v-if="info.ostatus === 99 || info.ostatus ===100 || info.ostatus === 110|| info.ostatus === 115|| info.ostatus === 120 "
        @click="godaddy(info.orderid)">申请退款</span>
         </div>

         <span v-if='info.exchangestatus === 1'
          @click="godadds(1)">
            退款申请中
          </span>
            <span v-if='info.exchangestatus === 10'
            @click="godadds(2)">
          >
            同意退款
          </span>
            <span v-if='info.exchangestatus === 110'
            @click="godadds(3)">
          >
            退款已完成
          </span>
            <span  v-if='info.exchangestatus === 210'
            @click="godadds(4)">
          >
            退款已拒绝
          </span>







        <span v-if="info.ostatus === 0"
        @click="topay(info)">去支付</span>








        <!-- <span @click="operate('Share')">分享</span> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {cancle,del,refund,affirm,sign} from '../../../service/api'
import {ORDER_DETAIL} from '../../../service/order'
export default {
  name: "OrderItem",
  props: {
    length,
    info: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  data(){
    return{
      status:''
    }
  },
  methods: {
    wareNum(wares) {
      return wares.reduce((sum, cur) => {
        return sum + cur.product.pnum;
      }, 0);
    },
    //退款
     godadds(i){
      if(i == 2){
        Message({ message: "已同意退款" });
      }else if(i == 3){
         Message({ message: "退款已完成" });
      }else if(i == 4){
         Message({ message: "已拒绝退款" });
      }
    },
    //物流订单详情
    operate(type) {
      this.$emit("operate", type, this.info);
    },
    //取消订单
    cancelorder(id){
        cancle({
          uid:this.$store.getters['user/getUid'],
          orderid:id,
          reason:'不买了'
        }).then((res)=>{
          this.length()
        })
    },



    
  //删除订单
  delorder(id){
    del({
      uid:this.$store.getters['user/getUid'],
      orderid:id,
      reason:'不买了'
    }).then((res)=>{
      // console.log(res)
      this.length()
  })
  },
  //申请退款
  godaddy(id){
    refund({
      uid:this.$store.getters['user/getUid'],
      orderid:id,
      reason:'不买了'
    }).then((res)=>{
      // this.$message({ message: "申请成功！" });
      // console.log(res)
      this.length()
    })
  },
  //支付尾款
  tolastpay(info){
      this.$store.dispatch('order/orderAffirmInfo', info)
          this.$router.push({
          path: '/confirmPay',
          query: {
            orderAffirmInfo: JSON.stringify(info),
            flag:0,
          }
      })
  },
  //去支付s
  topay(info){
    // console.log(info)
      this.$store.dispatch('order/orderAffirmInfo', info)
          this.$router.push({
          path: '/confirmPay',
          query: {
            orderAffirmInfo: JSON.stringify(info)
          }
      })
  },
  //评价
  evaluate(info){
    this.$router.push({
      path:'/orderevaluate',
      query:{
        orderId:info.orderid
      }
    })
  },
  //确定收货
  receive(id){
    sign({
      uid:this.$store.getters['user/getUid'],
      orderid:id
    }).then(res=>{
      this.length()
      // console.log(res)
    })
  }
},
  
  mounted(){
    // console.log(this.$router.query)
    // if(this.$router.query && this.$router.query.orderId){
    //   ORDER_DETAIL({
    //     orderid:this.$router.query.orderId
    //   }).then(res=>{
    //     console.log(res)
    //     this.info = res.attachment.order
    //   })
    // }
    // console.log(this.info.ostatus)
    if (this.info.ostatus >= 90 && this.info.ostatus <= 100) {
        return this.status =  "待接收样本";
      } else if (this.info.ostatus == 130) {
        return this.status =  "待寄出样本";
      } else if (this.info.ostatus == 140) {
        return this.status =  "待碳化培育";
      } else if (this.info.ostatus > 140 && this.info.ostatus < 160) {
        return this.status =  "碳化培育中";
      } else if (this.info.ostatus >= 160 && this.info.ostatus < 190) {
        return this.status =  "国际认证中";
      } else if (this.info.ostatus == 190) {
        return this.status =  "待支付尾款";
      } else if (this.info.ostatus == 200) {
        return this.status =  "待发货";
      } else if (this.info.ostatus == 210) {
        return this.status =  "待收货";
      } else if (this.info.ostatus == 300) {
        return this.status =  "已签收";
      } else if (this.info.ostatus == 5000 || this.info.ostatus == 5010) {
        return this.status =  "已关闭";
      } else if (this.info.ostatus == 0) {
        return this.status = "待支付";
      } else {
        return this.status =  "";
      }
     
  },
      
};
</script>
<style lang="less" scoped>
.order-item {
  border-bottom: 1px solid #cfcfcf;
  .header {
    height: 40px;
    line-height: 40px;
    display: flex;
    background: #f5f5f5;
    color: #333;
    font-size: 14px;
    .time {
      width: 120px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .order {
      width: 310px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .count {
      width: 144px;
      text-align: center;
    }
    .price,
    .operate {
      flex: 1;
      text-align: center;
    }
  }
  .cotainer {
    display: flex;
    position: relative;
    .ware-list {
      border-right: 1px solid #cfcfcf; /*no*/
      .ware-item {
        width: 574px;
        height: 140px;
        min-height: 100px; /*no*/
        display: flex;
        align-items: center;
        img {
          width: 80px;
          height: 80px;
          min-width: 56px; /*no*/
          min-height: 56px; /*no*/
          border-radius: 8px;
          margin-right: 40px;
        }
        .ware-info {
          width: 310px;
          // height: 80px;
          display: flex;
          flex-direction: column;
          > span {
            font-size: 12px;
            line-height: 1.8;
            color: #999;
          }
          .warename {
            font-size: 14px;
            color: #000;
          }
        }
        .count {
          flex: 1;
          text-align: center;
        }
      }
      .ware-item {
        border-bottom: 1px solid #cfcfcf; /*no*/
      }
      .ware-item:last-child {
        border-bottom: none;
      }
    }
    .price,
    .operate {
      flex: 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
      line-height: 1.5;
      > span {
        cursor: pointer;
      }
    }
    .operate {
      color: #999;
      span:first-child{
        font-size: 16px;
      }
      span:nth-child(2) {
        color: #921d23;
      }
    }
  }
}

.status{
  color: #999999;
}
</style>
