<template>
  <div class="order" v-loading="loading">
    <div class="header">
      <divider-title text="我的订单" />
      <div class="no-data-label" v-show="!orderFilterShow">您还没有订单</div>
      <div class="filter" v-show="orderFilterShow">
        <el-select
          class="selector"
          v-model="searchData.type"
          popper-class="order-popper"
          placeholder="请选择"
          @change="selectChange"
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          class="selector"
          v-model="searchData.tstr"
          popper-class="order-popper"
          placeholder="请选择"
          @change="selectChange"
        >
          <el-option
            v-for="item in timeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="no-data-container">
      <div class="no-data" v-show="!orderList.length">
        <div class="go-irisgem" @click="$router.push('/production.html')">
          <span>
            访问伊莉丝钻石官网
            <i class="el-icon-caret-right"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="container" v-show="orderList.length">
      <div class="list">
        <order-item
          v-for="item in orderList"
          :key="item.orderid"
          :info="item"
          @operate="orderOperate"
          :length='getOrderList'
        />
      </div>
      <div class="pagenation">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="searchData.size"
          :total="orderTotal"
          hide-on-single-page
          @current-change="pageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import dividerTitle from "@/components/dividerTitle";
import orderItem from "./components/orderItem";

import { GET_ORDER } from "../../service/order";
export default {
  name: "Order",
  components: { dividerTitle, orderItem },
  data() {
    return {
      loading: false,
      statusList: [
        { value: 0, label: "所有订单" },
        { value: 1, label: "待支付" },
        { value: 2, label: "待收货" },
        { value: 3, label: "待评价" },
        { value: 4, label: "待发货" }
      ],
      timeList: [
        { value: 0, label: "全部" },
        { value: 1, label: "过去3个月" },
        { value: 2, label: "过去6个月" }
        // { value: 1, label: "过去30天" },
        // { value: 4, label: "过去1年" },
      ],
      searchData: {
        size: 5,
        start: 0,
        tstr: 0,
        type: 0
      },
      orderList: [],
      orderFilterShow: false,
      orderTotal: 0
    };
  },
  mounted() {
    this.getOrderList();
  },
  watch:{
     'orderList.length':function(to,form){

    }
  },
  methods: {
    getOrderList() {
      this.loading = true;
      GET_ORDER({ ...this.searchData }).then(res => {
        console.log(res)
        this.loading = false;
        this.orderList = res.attachment.orders || [];
        this.orderTotal = res.attachment.total;
        if (this.orderList.length && !this.orderFilterShow) {
          this.orderFilterShow = true;
        }
        // console.log(res);
      });
    },



    orderOperate(type, order,status) {
      if (type === "Share") {
        return;
      }
      let name = "Order" + type;
      console.log(name)
      this.$router.push({
        name,
        query: { orderId: order.orderid, orderNo: order.orderno,ordersta:status,ostatus:order.ostatus,info:order }
      });
    },



    pageChange(val) {
      this.searchData.start = (val - 1) * this.searchData.size;
      this.getOrderList();
       document.getElementById("sapp").scrollTop = 0
    },



    selectChange() {
      this.searchData.start = 0;
      this.getOrderList();
    }

    
  }
};
</script>
<style lang="less" scoped>
.order {
  background: #f5f5f5;
  box-shadow: 0px 4px 5px 2px #d6d6d6;
  padding-bottom: 86px;
  .header {
    padding-top: 28px;
    height: 96px;
    position: relative;
    background: #fff;
    .no-data-label {
      margin-left: 92px;
      font-size: 13px;
      color: #666;
      line-height: 20px;
      padding-top: 12px;
    }
    .filter {
      position: absolute;
      right: 60px;
      bottom: 10px;
      .selector {
        width: 130px;
        margin-left: 36px;
      }
    }
  }
  // .no-data-container {
  //   background: #f5f5f5;
  .no-data {
    height: 420px;
    padding: 35px 72px 0;
    margin: 0 20px;
    background: #fff;
    box-shadow: 0px 4px 5px 2px #d6d6d6;
    .go-irisgem {
      height: 82px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f5f5;
      > span {
        padding: 0 20px;
        // width: 130px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 13px;
        color: #666;
        border: 1px solid #666; /*no*/
        cursor: pointer;
        i {
          font-size: 16px;
          color: #901d22;
        }
      }
    }
  }
  // }

  .container {
    padding: 20px 20px 0;
  }
  .list {
    padding: 15px 20px 0;
    background: #fff;
    box-shadow: 0px 4px 5px 2px #d6d6d6;
  }
  .pagenation {
    background: #fff;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
<style lang="less">
.order {
  .el-input__inner {
    height: 30px;
    border-radius: 0;
  }
  .el-select .el-input.is-focus .el-input__inner {
    border-color: #999999;
  }
  .el-select .el-input__inner:focus {
    border-color: #999999;
  }
  .el-input__icon {
    line-height: 30px;
  }
}
.order-popper {
  .el-select-dropdown__item.selected {
    color: #000;
    font-weight: 700;
  }
}
</style>
